.nav {
  width: 100%;
  // max-width: 1240px;
  // padding-left: 5%;
  // padding-right: 5%;
  height: 60px;
  display: flex;
  align-content: center;

  // justify-content: space-between;
  // align-items: center;
}

.header {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #ec780e;
}

.header-container {
  display: flex;
  justify-content: center;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
}

.nav ul {
  list-style-type: none;
  padding: 1rem 0px;
  box-sizing: border-box;
  margin: 0px;
  font-size: 1em;
  text-align: right;
  font-weight: 100;
  display: flex;
  // align-items: center;
  // justify-content: flex-end;
}

.nav li {
  margin-left: 1.5rem;
  line-height: 3;
}

.nav a {
  color: white !important;
  font-weight: 500;
}

.nav a.is-active {
  color: #f9d469 !important;
}

.profile {
  font-weight: 600 !important;
}

.popover {
  padding: 0px;
  width: 340px;
  max-height: 40vh;
  overflow-y: scroll;
}
.ant-popover{
  position: fixed;
}
.popover-header {
  display: flex;
  justify-content: space-between;
  padding: 8px 14px;
  border-bottom: #eceef0 1px solid;
}

.popover-title {
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0px;
  color: #212e36;
}
.popover-read-all {
  text-align: right;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #eb7304;
}

.popover-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 14px;
  border-bottom: #eceef0 1px solid;
}
.special {
  color: #eb7304;
}
.popover-item-title {
  font-size: 14px;
  font-weight: normal;
  font-weight: bold;
}
.popover-item-description {
  font-size: 14px;
  font-weight: normal;
}
.popover-footer {
  display: flex;
  justify-content: space-between;
  color: #71757d;
  opacity: 0.6;
}
.full-width {
  width: 100%;
}
.popover-requst-footer {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
}
