.card-title {
  font-size: medium;
  text-align: left;
  margin-bottom: 0;
  font-weight: 700;
}

.card-second-title {
  font-size: small;
  font-weight: 600;
  color: grey;
}

.data {
  text-align: left;
  font-size: smaller;
  font-weight: 800;
}

.members {
  text-align: right !important;
  font-weight: 800;
}

.icon {
  color: #EC780E !important;
  padding: 0 0.2rem 0 0;
}

.partner-type {
  font-weight: 700;
  color: grey;
}

.partner-name {
  font-weight: 800;
}

.card-title.partners {
  padding: 1rem;
}

.ant-select-selector {
  border-radius: 10px !important;
}

.ant-form-item-control {
  min-width: 150px;
}

.page-header {
  font-weight: 700;
  font-size: 1.5rem;
}

.count {
  font-weight: 700;
  color: grey;
  margin-bottom: 1rem;
}

.divider {
  margin-bottom: 1rem;
  margin-top: 0;
}

.content-img {
  width: 100%;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.card .footer {
  padding: 0.5rem;
  background-color: white;
  border-radius: 10px;
}

.ant-checkbox-input {
  border-radius: 10px !important;
}

.tag {
  height: 2rem;
  margin: 0.2rem 0rem 0.2rem 0.1rem;
  line-height: 2rem;
  font-weight: 700;
  border-radius: 12px !important;
}

.moderator {
  height: 3rem;
  line-height: 3rem;
  margin-bottom: 1rem;
  text-align: center !important;
}

.rating-row {
  border-top: 1px solid whitesmoke;
  padding-top: 5px;
}

.rating-container {
  // padding: 0 1rem 0.5rem 1rem;
  // height: 384px;
}

.tier-table {
  .tier-table-head {
    text-align: center;
    width: 15% !important;
    color: grey;
    border-bottom: 1px solid whitesmoke;
    padding-bottom: 1rem;
  }

  .required {
    width: 50% !important;
  }

  .tier-table-header {
    text-align: center;
  }

  .points-table {
    width: 100%;
  }

  td {
    padding: 1rem 0rem 1rem 0rem !important;
    width: 5rem;
    font-weight: 600;
  }

  td.tier {
    font-weight: 700 !important;
  }

  tbody {
    text-align: center !important;
    tr {
      border-bottom: 2px solid whitesmoke;
      tr {
        border-bottom: 0;
      }
    }
  }
}

.points-rating-row {
  padding-top: 1rem !important;
  // padding-bottom: 0.5rem !important;
  border-bottom: 2px solid whitesmoke;
}
.star-count-value {
  font-weight: 700 !important;
  font-size: 1rem;
}

.points-input {
  background-color: whitesmoke !important;
  max-width: 5rem;
  margin: 0 0.5rem 0;
  border: 0;
  text-align: right !important;
}

.points-rating-row {
  .points-input {
    margin-bottom: 1rem !important;
  }
}

.single-partner {
  .name {
    font-weight: 700;
    font-size: 1.5rem;
  }
  .address {
    font-weight: 500;
    color: grey;
  }

  .anticon {
    padding: 0.5rem 1rem 0.5rem 0rem;
  }
  .tags {
    margin-top: 2rem;
    .tag {
      background-color: #ebebeb;
      color: black;
      padding: 0rem 0.5rem 0.5rem 0.5rem;
      margin-bottom: 0.5rem;
      font-weight: 500;
      border: 0 !important;
      // border-radius: 10px;
    }
  }

  .about {
    margin-top: 2rem;
    margin-bottom: 2rem;

    .title {
      font-weight: 600;
      font-size: 1.5rem;
      padding-bottom: 1rem;
    }
  }

  .socials {
    .title {
      font-weight: 600;
      font-size: 1.5rem;
      padding-bottom: 1rem;
    }
    .social-icons {
      margin-right: 1rem;
    }
  }
}

.rewards-table {
  .ant-input {
    background-color: whitesmoke !important;
    // max-width: 5rem;
    max-width: 80%;
    margin: 0 0.5rem 0;
    border: 0;
    text-align: left !important;
  }
  th {
    text-align: center;
    padding: 1rem;
  }
  td {
    text-align: center;
  }
  thead {
    border-bottom: 2px solid whitesmoke;
    width: 80% !important;
  }
}

.ant-input-affix-wrapper {
  border: 0;
}

.ant-select-selector {
  border: 0 !important;
}
.ant-picker-range {
  border: 0;
}

.ant-table-cell {
  text-align: center;
}

.add-partner {
  .ant-input {
    background-color: whitesmoke;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: whitesmoke;
  }
}
.add-partner-modal {
  .ant-modal-footer {
    border-top: 0;
  }
  .ant-modal-header {
    border-bottom: 0;
  }
  .ant-modal-title {
    font-weight: 700;
    font-size: 1.2rem;
  }
}

.edit-moderator-modal {
  .ant-modal-footer {
    border-top: 0;
  }
  .ant-modal-header {
    border-bottom: 0;
  }
  .ant-modal-title {
    font-weight: 700;
    font-size: 1.2rem;
  }
}

.table-row:hover {
  cursor: pointer;
}

.partner-edit-form {
  .tags {
    width: 100%;
    background-color: white;
    padding: 0.5rem 1rem 3rem;
  }
  .tag-input {
    width: 100px;
    border: 3px solid #ebebeb !important;
    padding: 0 !important;
  }
}
.ant-table-tbody {
  td:first-child {
    font-weight: bold;
  }
}

.partner-table {
  .ant-row {
    align-items: center;
  }
  th:not(:first-child) {
    text-align: center !important;
  }
}

.remove-partner-modal {
  width: 610px !important;

  Button {
    margin-left: 0.5rem;
  }

  .ok-btn {
    color: white;
    background-color: #ff4141 !important;
    float: left;
  }
}

.leaderboard-table {
  thead {
    th {
      text-align: center;
    }
    th:nth-child(2) {
      text-align: left !important;
    }
  }

  td:nth-child(2) {
    font-weight: bold;
  }
}

.moderator-table {
  thead {
    th {
      text-align: center !important;
    }
    th:nth-child(1) {
      text-align: left !important;
    }
  }
}

.tags
  .ant-tag{
    border-radius: 12px !important;
}
