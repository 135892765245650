@media only screen and (max-width: 1920px) {
}

//   @media only screen and (min-width:1400) and (max-width:2000px){
//       .content{
//           width: 15% !important;
//       }
//   }

.verification {
  background-image: url("../../../assets/sl_bg.png");
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


.spinner{
  color: white;
}
.ant-spin-text{
  font-size: 22px;
}