@media only screen and (max-width: 1920px) {
}

//   @media only screen and (min-width:1400) and (max-width:2000px){
//       .content{
//           width: 15% !important;
//       }
//   }

.sign-in-page {
  background-image: url("../../../assets/sl_bg.png");
  height: 100vh;
}

.content {
  width: 360px;
}

.logo {
  max-width: 200px !important;
  text-align: center !important;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2rem;
}
