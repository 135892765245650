.card-title {
  font-size: medium;
  text-align: left;
  margin-bottom: 0;
  font-weight: 700;
}

.card-second-title {
  font-size: small;
  font-weight: 600;
  color: grey;
}

.data {
  text-align: left;
  font-size: smaller;
  font-weight: 800;
}

.members {
  text-align: right !important;
  font-weight: 800;
}

.icon {
  color: #EC780E !important;
  padding: 0 0.2rem 0 0;
}

.partner-type {
  font-weight: 700;
  color: grey;
}

.partner-name {
  font-weight: 800;
}

.partner {
  display: flex;
  flex-direction: column;
}

.card-title.partners {
  padding: 1rem;
}

.ant-select-selector {
  border-radius: 10px !important;
}

.ant-form-item-control {
  min-width: 150px;
}

.page-header {
  font-weight: 700;
  font-size: 1.5rem;
}

.form-item {
  width: auto;
}

.count {
  font-weight: 700;
  color: grey;
  margin-bottom: 1rem;
}

.divider {
  margin-bottom: 1rem;
}

.content-img {
  width: 100%;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.card .footer {
  padding: 0.5rem;
  background-color: white;
  border-radius: 10px;
}

.ant-checkbox-input {
  border-radius: 10px !important;
}

.tag {
  height: 2rem;
  margin: 0.2rem 0rem 0.2rem 0.5rem;
  line-height: 2rem;
  font-weight: 700;
}

.moderator {
  height: 3rem;
  line-height: 3rem;
  margin-bottom: 1rem;
  text-align: center !important;
}

.rating-row {
  border-top: 1px solid whitesmoke;
  padding-top: 5px;
}

.rating-container {
  padding: 0 1rem 0.5rem 1rem;
}

.tier-table {
  .tier-table-head {
    text-align: center;
    width: 15% !important;
  }

  .required {
    width: 50% !important;
  }

  .tier-table-header {
    text-align: center;
  }

  .points-table {
    width: 100%;
  }

  td {
    padding: 1rem 0rem 1rem 0rem !important;
    width: 5rem;
  }

  tbody {
    text-align: center !important;
  }
}

.points-rating-row {
  padding-top: 1rem !important;
  // padding-bottom: 0.5rem !important;
}
.star-count-value {
  font-weight: 700 !important;
  font-size: 1rem;
}

.points-input {
  background-color: whitesmoke !important;
  max-width: 5rem;
  margin: 0 0.5rem 0;
  border: 0;
  text-align: right !important;
}

.points-rating-row {
  .points-input {
    margin-bottom: 1rem !important;
  }
}

.top-users {
  height: 100%;
}
.ant-upload.ant-upload-select-picture-card{
  width: 86px;
  height: 86px;
  background-color: #C4C4C4;
  color: #FFFFFF;
  font-size: 36px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}